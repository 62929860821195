import { AssertByTypename } from '@/helpers/graphql-model-helper';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { GetNodeTitleDetailsQuery } from '@/pages/graphql/queries/GetNodeTitleDetails.query';

export type UpcomingRelease = AssertByTypename<
	GetNodeTitleDetailsQuery['node'],
	'Movie' | 'Show' | 'Season'
>['content']['upcomingReleases'][number];

export function includeGenericReleases(
	title: TitleDetail,
	upcomingReleases: UpcomingRelease[],
	hasCinemaOffers: boolean,
	hasDigitalOffers: boolean
) {
	const genericReleases = title.content.upcomingReleases.filter(
		release => !release.package || ['imd', 'tmd'].includes(release.package.shortName)
	);

	const genericDigital = genericReleases.find(release => release.releaseType === 'DIGITAL');
	const genericTheatrical = genericReleases.find(release => release.releaseType === 'THEATRICAL');

	// due to lack of confidence in theatrical generic release dates, we don't want to show them if there is a digital release with the same date
	// it may be that we expand the net to hide generic theatrical if there is any upcoming digital release, but for now just these
	const isGenericTheatricalWithSameDateAsDigitalUpcoming = upcomingReleases
		.filter(el => el.releaseType === 'DIGITAL')
		.some(el => genericTheatrical && el.releaseDate === genericTheatrical.releaseDate);

	if (!upcomingReleases.some(release => release.releaseType === 'DIGITAL') && !!genericDigital && !hasDigitalOffers) {
		upcomingReleases.push(genericDigital);
	}

	if (
		!upcomingReleases.some(release => release.releaseType === 'THEATRICAL') &&
		!!genericTheatrical &&
		!hasCinemaOffers &&
		!isGenericTheatricalWithSameDateAsDigitalUpcoming
	) {
		upcomingReleases.push(genericTheatrical);
	}

	return upcomingReleases;
}
