import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type ProfileInfoFragment = {
	__typename: 'Profile';
	displayName: string;
	avatarUrl?: string | null;
	profileUrl: string;
	profileType: Types.ProfileType;
	id: string;
};

export const ProfileInfoFragmentDoc = gql`
	fragment ProfileInfo on Profile {
		__typename
		id: uuid
		displayName
		avatarUrl
		profileUrl
		profileType
	}
`;
