import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';
import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { LastVistedTitleDetails } from '@/stores/title-details.store';

// ?preferred_variant=BUYBOX_SORTING_EXP:control
// ?preferred_variant=BUYBOX_SORTING_EXP:variant_1
// ?preferred_variant=BUYBOX_SORTING_EXP:variant_2

// ?remove_preferred_experiment=POPULAR_EXP

export const BuyBoxSortingExpName = 'BUYBOX_SORTING_EXP' as const;

export type BuyBoxSortingExpVariants = ToVariants<typeof BuyBoxSortingExpVariants>;

export const BuyBoxSortingExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
} as const;

export default generate(
	BuyBoxSortingExpName,
	BuyBoxSortingExpVariants,
	() => true,
	async instance => {
		const routingFullPath = instance.$store.state?.routing.activeRoute.fullPath;
		const titleDetailsFullPath = (
			instance.$store.state?.titleDetails?.lastVisitedTitleDetails as LastVistedTitleDetails
		)?.fullPath;

		if (routingFullPath !== titleDetailsFullPath) {
			await new Promise(resolve => {
				let unwatch: () => void = () => {};

				const timeoutId = setTimeout(() => {
					unwatch(); // Stop watching after timeout
					resolve(false);
					captureMessageForSentry(
						'[BuyBoxSortingExp isExperimentReady() watcher forced to be resolved after 5s]:',
						{ where: 'BuyBoxSortingExp' },
						'info'
					);
				}, 5000);

				unwatch = instance.$watch(
					() =>
						(instance.$store.state?.titleDetails?.lastVisitedTitleDetails as LastVistedTitleDetails)
							?.fullPath,
					newPath => {
						if (newPath === routingFullPath) {
							unwatch(); // Stop watching
							resolve(true);
							clearTimeout(timeoutId);
						}
					},
					{ immediate: true }
				);
			});
		}
		return !!(instance.$store.state?.titleDetails?.lastVisitedTitleDetails as LastVistedTitleDetails)?.offers?.some(
			offer => ['atp', 'hlu', 'amp', 'dnp'].includes(offer.package.shortName)
		);
	}
);
