import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=FT_SWIPER_EXP_2:control
// ?preferred_variant=FT_SWIPER_EXP_2:variant_1

// ?remove_preferred_experiment=FT_SWIPER_EXP_2

export const FTSwiperExpName = 'FT_SWIPER_EXP_2' as const;

export type FTSwiperExpVariants = ToVariants<typeof FTSwiperExpVariants>;

export const FTSwiperExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
} as const;

export default generate(FTSwiperExpName, FTSwiperExpVariants);
