import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { BuyBoxOffersFragmentDoc } from './BuyboxOffers.fragment';
export type GetBuyBoxOffersQueryVariables = Types.Exact<{
	nodeId: Types.Scalars['ID']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	platform?: Types.Platform;
}>;

export type GetBuyBoxOffersQuery = {
	__typename?: 'Query';
	node?:
		| { __typename?: 'Article'; id: string }
		| { __typename?: 'Author'; id: string }
		| { __typename?: 'Bundle'; id: string }
		| { __typename?: 'Device'; id: string }
		| {
				__typename: 'Episode';
				id: string;
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							iconWide: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
		  }
		| { __typename?: 'GenericTitleList'; id: string }
		| { __typename?: 'Genre'; id: string }
		| {
				__typename: 'Movie';
				id: string;
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							iconWide: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
		  }
		| { __typename?: 'MultiStepSportEvent'; id: string }
		| { __typename?: 'Offer'; id: string }
		| { __typename?: 'Package'; id: string }
		| { __typename?: 'Page'; id: string }
		| { __typename?: 'Person'; id: string }
		| {
				__typename: 'Season';
				id: string;
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							iconWide: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
		  }
		| {
				__typename: 'Show';
				id: string;
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					offerSeasons?: Array<string> | null;
					minRetailPrice?: string | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					newElementCount?: number | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					preAffiliatedStandardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						icon: string;
						iconWide: string;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							iconWide: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
		  }
		| { __typename?: 'SingleStepSportEvent'; id: string }
		| { __typename?: 'SportCompetitionV2'; id: string }
		| { __typename?: 'SportCompetitorV2'; id: string }
		| { __typename?: 'Url'; id: string }
		| null;
};

export const GetBuyBoxOffersDocument = gql`
	query GetBuyBoxOffers($nodeId: ID!, $country: Country!, $language: Language!, $platform: Platform! = WEB) {
		node(id: $nodeId) {
			id
			...BuyBoxOffers
		}
	}
	${BuyBoxOffersFragmentDoc}
`;
