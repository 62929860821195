import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleOfferFragmentDoc, FastOfferFragmentDoc } from '../../buybox/graphql/fragments/Offer.fragment';
export type BuyBoxOffers_Episode_Fragment = {
	__typename: 'Episode';
	offerCount: number;
	maxOfferUpdatedAt?: any | null;
	flatrate: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	buy: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	rent: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	free: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	fast: Array<{
		__typename?: 'Offer';
		availableTo?: any | null;
		availableFromTime?: any | null;
		availableToTime?: any | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	bundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{
				__typename?: 'Package';
				icon: string;
				id: string;
				iconWide: string;
				clearName: string;
				packageId: number;
			}>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
};

export type BuyBoxOffers_Movie_Fragment = {
	__typename: 'Movie';
	offerCount: number;
	maxOfferUpdatedAt?: any | null;
	promotedBundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{ __typename?: 'Package'; icon: string; id: string; clearName: string; packageId: number }>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
	promotedOffers: Array<{
		__typename?: 'Offer';
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	flatrate: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	buy: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	rent: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	free: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	fast: Array<{
		__typename?: 'Offer';
		availableTo?: any | null;
		availableFromTime?: any | null;
		availableToTime?: any | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	bundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{
				__typename?: 'Package';
				icon: string;
				id: string;
				iconWide: string;
				clearName: string;
				packageId: number;
			}>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
};

export type BuyBoxOffers_Season_Fragment = {
	__typename: 'Season';
	offerCount: number;
	maxOfferUpdatedAt?: any | null;
	promotedBundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{ __typename?: 'Package'; icon: string; id: string; clearName: string; packageId: number }>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
	promotedOffers: Array<{
		__typename?: 'Offer';
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	flatrate: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	buy: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	rent: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	free: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	fast: Array<{
		__typename?: 'Offer';
		availableTo?: any | null;
		availableFromTime?: any | null;
		availableToTime?: any | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	bundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{
				__typename?: 'Package';
				icon: string;
				id: string;
				iconWide: string;
				clearName: string;
				packageId: number;
			}>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
};

export type BuyBoxOffers_Show_Fragment = {
	__typename: 'Show';
	offerCount: number;
	maxOfferUpdatedAt?: any | null;
	promotedBundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{ __typename?: 'Package'; icon: string; id: string; clearName: string; packageId: number }>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
	promotedOffers: Array<{
		__typename?: 'Offer';
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	flatrate: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	buy: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	rent: Array<{
		__typename?: 'Offer';
		offerSeasons?: Array<string> | null;
		minRetailPrice?: string | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	free: Array<{
		__typename?: 'Offer';
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		availableTo?: any | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	fast: Array<{
		__typename?: 'Offer';
		availableTo?: any | null;
		availableFromTime?: any | null;
		availableToTime?: any | null;
		id: string;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		newElementCount?: number | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		currency?: string | null;
		lastChangeRetailPriceValue?: number | null;
		type: Types.OfferType;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		elementCount?: number | null;
		subtitleLanguages: Array<any>;
		videoTechnology: Array<Types.VideoTechnology>;
		audioTechnology: Array<Types.AudioTechnology>;
		audioLanguages: Array<any>;
		package: {
			__typename?: 'Package';
			id: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			icon: string;
			iconWide: string;
			planOffers: Array<{
				__typename?: 'PackagePlanOffer';
				title?: string | null;
				retailPrice?: string | null;
				isTrial?: boolean | null;
				durationDays?: number | null;
				retailPriceValue?: number | null;
				children: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
				}>;
			}>;
		};
	}>;
	bundles: Array<{
		__typename?: 'PromotedBundleEdge';
		promotionUrl: string;
		node: {
			__typename?: 'Bundle';
			id: string;
			clearName: string;
			icon: string;
			technicalName: string;
			bundleId: number;
			packages: Array<{
				__typename?: 'Package';
				icon: string;
				id: string;
				iconWide: string;
				clearName: string;
				packageId: number;
			}>;
		};
		offer: {
			__typename?: 'Offer';
			id: string;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			newElementCount?: number | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			currency?: string | null;
			lastChangeRetailPriceValue?: number | null;
			type: Types.OfferType;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			elementCount?: number | null;
			availableTo?: any | null;
			subtitleLanguages: Array<any>;
			videoTechnology: Array<Types.VideoTechnology>;
			audioTechnology: Array<Types.AudioTechnology>;
			audioLanguages: Array<any>;
			package: {
				__typename?: 'Package';
				id: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				icon: string;
				iconWide: string;
				planOffers: Array<{
					__typename?: 'PackagePlanOffer';
					title?: string | null;
					retailPrice?: string | null;
					isTrial?: boolean | null;
					durationDays?: number | null;
					retailPriceValue?: number | null;
					children: Array<{
						__typename?: 'PackagePlanOffer';
						title?: string | null;
						retailPrice?: string | null;
						isTrial?: boolean | null;
						durationDays?: number | null;
						retailPriceValue?: number | null;
					}>;
				}>;
			};
		};
	}>;
};

export type BuyBoxOffersFragment =
	| BuyBoxOffers_Episode_Fragment
	| BuyBoxOffers_Movie_Fragment
	| BuyBoxOffers_Season_Fragment
	| BuyBoxOffers_Show_Fragment;

export const BuyBoxOffersFragmentDoc = gql`
	fragment BuyBoxOffers on MovieOrShowOrSeasonOrEpisode {
		__typename
		offerCount(country: $country, platform: $platform)
		maxOfferUpdatedAt(country: $country, platform: $platform)
		flatrate: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [FLATRATE, FLATRATE_AND_BUY, CINEMA], bestOnly: true, preAffiliate: true }
		) {
			...TitleOffer
		}
		buy: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [BUY], bestOnly: true, preAffiliate: true }
		) {
			...TitleOffer
			offerSeasons
			minRetailPrice(country: $country, platform: $platform, language: $language)
		}
		rent: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [RENT], bestOnly: true, preAffiliate: true }
		) {
			...TitleOffer
			offerSeasons
			minRetailPrice(country: $country, platform: $platform, language: $language)
		}
		free: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [FREE, ADS], bestOnly: true, preAffiliate: true }
		) {
			...TitleOffer
		}
		fast: offers(
			country: $country
			platform: $platform
			filter: { monetizationTypes: [FAST], bestOnly: true, preAffiliate: true }
		) {
			...FastOffer
		}
		bundles(country: $country, platform: WEB) {
			node {
				id
				clearName
				icon(profile: S100)
				technicalName
				bundleId
				packages(country: $country, platform: $platform) {
					icon
					id
					iconWide(profile: S160)
					clearName
					packageId
				}
			}
			promotionUrl
			offer {
				...TitleOffer
			}
		}
		... on MovieOrShowOrSeason {
			promotedBundles(country: $country, platform: WEB) {
				node {
					id
					clearName
					icon(profile: S100)
					technicalName
					bundleId
					packages(country: $country, platform: $platform) {
						icon
						id
						clearName
						packageId
					}
				}
				promotionUrl
				offer {
					...TitleOffer
				}
			}
			promotedOffers(country: $country, platform: WEB, filter: { bestOnly: true, preAffiliate: true }) {
				...TitleOffer
				minRetailPrice(country: $country, platform: $platform, language: $language)
			}
		}
	}
	${TitleOfferFragmentDoc}
	${FastOfferFragmentDoc}
`;
