import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleOfferFragmentDoc, FastOfferFragmentDoc } from '../fragments/Offer.fragment';
export type GetTitleOffersPromotedByFullpathQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
}>;

export type GetTitleOffersPromotedByFullpathQuery = {
	__typename?: 'Query';
	urlV2: {
		__typename?: 'Url';
		id: string;
		node:
			| { __typename?: 'Article'; id: string }
			| { __typename?: 'Author'; id: string }
			| { __typename?: 'Bundle'; id: string }
			| { __typename?: 'Device'; id: string }
			| { __typename?: 'Episode'; id: string }
			| { __typename?: 'GenericTitleList'; id: string }
			| { __typename?: 'Genre'; id: string }
			| {
					__typename?: 'Movie';
					id: string;
					promotedOffers: Array<{
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					}>;
			  }
			| { __typename?: 'MultiStepSportEvent'; id: string }
			| { __typename?: 'Offer'; id: string }
			| { __typename?: 'Package'; id: string }
			| { __typename?: 'Page'; id: string }
			| { __typename?: 'Person'; id: string }
			| {
					__typename?: 'Season';
					id: string;
					promotedOffers: Array<{
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					}>;
			  }
			| {
					__typename?: 'Show';
					id: string;
					promotedOffers: Array<{
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						newElementCount?: number | null;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						preAffiliatedStandardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						package: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							clearName: string;
							shortName: string;
							technicalName: string;
							icon: string;
							iconWide: string;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					}>;
			  }
			| { __typename?: 'SingleStepSportEvent'; id: string }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string }
			| { __typename?: 'Url'; id: string };
	};
};

export const GetTitleOffersPromotedByFullpathDocument = gql`
	query GetTitleOffersPromotedByFullpath($fullPath: String!, $country: Country!, $language: Language!) {
		urlV2(fullPath: $fullPath) {
			id
			node {
				id
				... on MovieOrShowOrSeason {
					promotedOffers(country: $country, platform: WEB, filter: { bestOnly: true }) {
						...TitleOffer
					}
				}
			}
		}
	}
	${TitleOfferFragmentDoc}
`;
