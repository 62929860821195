import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type TitleListData_Movie_Fragment = {
	__typename: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleListData_Season_Fragment = {
	__typename: 'Season';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenState: { __typename?: 'ShowSeenState'; progress: number };
	show: {
		__typename: 'Show';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		seenState: { __typename?: 'ShowSeenState'; progress: number };
		tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	};
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleListData_Show_Fragment = {
	__typename: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	seenState: { __typename?: 'ShowSeenState'; progress: number };
	tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type TitleListDataFragment =
	| TitleListData_Movie_Fragment
	| TitleListData_Season_Fragment
	| TitleListData_Show_Fragment;

export const TitleListDataFragmentDoc = gql`
	fragment TitleListData on MovieOrShowOrSeason {
		__typename
		id
		objectId
		objectType
		dislikelistEntry {
			createdAt
		}
		likelistEntry {
			createdAt
		}
		... on MovieOrShow {
			watchlistEntryV2 {
				createdAt
			}
			customlistEntries {
				createdAt
			}
		}
		... on Show {
			seenState(country: $country) {
				progress
			}
			tvShowTrackingEntry {
				createdAt
			}
		}
		... on Season {
			seenState(country: $country) {
				progress
			}
			show {
				__typename
				id
				objectId
				objectType
				watchlistEntryV2 {
					createdAt
				}
				seenState(country: $country) {
					progress
				}
				tvShowTrackingEntry {
					createdAt
				}
				customlistEntries {
					createdAt
				}
			}
		}
	}
`;
