import { ObjectType } from '@/@types/graphql-types';
import { ShareEvents } from '@/enums/events';
import { ShareMethod } from '@/enums/share-methods';
import { ClassicUrl, safeOpen } from '@/helpers/clickout-helper';
import { addToast } from '@/helpers/toast/toast-helper';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getVm } from '@/helpers/vm-helper';
import { TrackingProviderPropertiesInterface } from './tracking/providers';

export interface ShareContent {
	url?: string;
	text?: string;
	method?: ShareMethod;
}

interface ShareTitle {
	objectId: number;
	objectType: ObjectType;
	content: { fullPath: string; title: string };
}

interface ShareArguments {
	title: ShareTitle;
	tracking: TrackingProviderPropertiesInterface;
	content?: ShareContent;
	/** Use the native `navigator.share` browser API. */
	useNative?: boolean;
	additionalContexts?: SnowplowContext[];
}

export const ShareHelper = {
	async share({ tracking, title, content, useNative = true, additionalContexts }: ShareArguments) {
		const shareUrl: string = content?.url ?? getShareUrl(title.content.fullPath);
		const shareText: string = content?.text ?? `${title.content.title ?? document.title}`;

		if (process.server) {
			return;
		}

		trackEvent(title, tracking, additionalContexts);

		const navigator: Navigator = window.navigator;
		if (useNative && navigator && navigator.share) {
			await navigator.share({ url: shareUrl, text: shareText });
			trackEvent(title, tracking, additionalContexts);
			return;
		}

		trackEvent(title, tracking, additionalContexts);
		switch (content?.method) {
			case ShareMethod.FACEBOOK: {
				copyToClipboard(shareText);
				safeOpen(
					new ClassicUrl('https://www.facebook.com/dialog/share')
						.set('app_id', JW_CONFIG.FACEBOOK_APP_ID)
						.set('display', 'popup')
						.set('href', shareUrl)
				);
				break;
			}
			case ShareMethod.TWITTER:
				safeOpen(new ClassicUrl('https://x.com/intent/post').set('url', shareUrl).set('text', shareText));
				break;
			default:
				copyToClipboard(shareUrl);
		}
	},
};

/**
 * If possible, copies the given text to the user's clipboard.
 * Returns a boolean indicating success/failure.
 */
export function copyToClipboard(content: string) {
	if (navigator && navigator.clipboard) {
		navigator.clipboard.writeText(content);
		addToast({ message: getVm().$t('WEBAPP_COPIED_TO_CLIPBOARD') });
		return true;
	}

	return false;
}

function trackEvent(
	{ objectId, objectType }: ShareTitle,
	properties: TrackingProviderPropertiesInterface,
	additionalContexts?: SnowplowContext[]
): void {
	const contexts: SnowplowContext[] = [
		new SnowplowTitleContextGraphql(objectId, objectType),
		...(additionalContexts ? additionalContexts : []),
	];

	TrackingHelper.trackEvent(ShareEvents.CATEGORY, properties, contexts);
}

export function getShareUrl(titleFullPath: string) {
	return new ClassicUrl(new URL(titleFullPath, `https://www.${JW_CONFIG.DOMAIN}`))
		.set('utm_source', 'share')
		.set('utm_medium', 'web')
		.toString();
}
