import * as Types from '../../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type PackageFragment = {
	__typename?: 'Package';
	clearName: string;
	id: string;
	shortName: string;
	technicalName: string;
	packageId: number;
	selected: boolean;
	monetizationTypes: Array<Types.MonetizationType>;
	icon: string;
	addonParent?: { __typename?: 'Package'; id: string } | null;
};

export type GetPackagesQueryVariables = Types.Exact<{
	platform?: Types.InputMaybe<Types.Platform>;
	country: Types.Scalars['Country']['input'];
}>;

export type GetPackagesQuery = {
	__typename?: 'Query';
	packages: Array<{
		__typename?: 'Package';
		clearName: string;
		id: string;
		shortName: string;
		technicalName: string;
		packageId: number;
		selected: boolean;
		monetizationTypes: Array<Types.MonetizationType>;
		icon: string;
		bundles: Array<{
			__typename?: 'Bundle';
			id: string;
			bundleId: number;
			technicalName: string;
			selected: boolean;
			packages: Array<{ __typename?: 'Package'; clearName: string }>;
		}>;
		addons: Array<{
			__typename?: 'Package';
			clearName: string;
			id: string;
			shortName: string;
			technicalName: string;
			packageId: number;
			selected: boolean;
			monetizationTypes: Array<Types.MonetizationType>;
			icon: string;
			addonParent?: { __typename?: 'Package'; id: string } | null;
		}>;
		addonParent?: { __typename?: 'Package'; id: string } | null;
	}>;
};

export const PackageFragmentDoc = gql`
	fragment Package on Package {
		clearName
		id
		shortName
		technicalName
		packageId
		selected
		monetizationTypes
		icon
		addonParent(country: $country, platform: WEB) {
			id
		}
	}
`;
export const GetPackagesDocument = gql`
	query GetPackages($platform: Platform = WEB, $country: Country!) {
		packages(country: $country, platform: $platform, includeAddons: false) {
			...Package
			bundles(platform: $platform, country: $country) {
				id
				bundleId
				technicalName
				selected
				packages(country: $country, platform: $platform) {
					clearName
				}
			}
			addons(country: $country, platform: $platform) {
				...Package
			}
		}
	}
	${PackageFragmentDoc}
`;
