import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from './WatchNowOffer.fragment';
import { TitleListDataFragmentDoc } from './TitleListData.fragment';
export type PosterTitle_Movie_Fragment = {
	__typename: 'Movie';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: {
		__typename?: 'MovieContent';
		title: string;
		posterUrl?: string | null;
		fullPath: string;
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseDate?: any | null;
			releaseCountDown?: number | null;
		}>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{ __typename?: 'AvailableTo'; availableToDate: any; availableCountDown: number }>;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type PosterTitle_Season_Fragment = {
	__typename: 'Season';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: {
		__typename?: 'SeasonContent';
		seasonNumber: number;
		title: string;
		posterUrl?: string | null;
		fullPath: string;
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseDate?: any | null;
			releaseCountDown?: number | null;
		}>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
	};
	show: {
		__typename: 'Show';
		id: string;
		objectId: number;
		objectType: Types.ObjectType;
		content: { __typename?: 'ShowContent'; title: string; fullPath: string };
		watchNowOffer?: {
			__typename: 'Offer';
			id: string;
			standardWebURL?: string | null;
			preAffiliatedStandardWebURL?: string | null;
			streamUrl?: string | null;
			retailPrice?: string | null;
			retailPriceValue?: number | null;
			lastChangeRetailPriceValue?: number | null;
			currency?: string | null;
			presentationType: Types.PresentationType;
			monetizationType: Types.MonetizationType;
			availableTo?: any | null;
			dateCreated?: any | null;
			newElementCount?: number | null;
			package: {
				__typename?: 'Package';
				id: string;
				icon: string;
				packageId: number;
				clearName: string;
				shortName: string;
				technicalName: string;
				iconWide: string;
				hasRectangularIcon: boolean;
			};
		} | null;
		watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		seenState: { __typename?: 'ShowSeenState'; progress: number };
		tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{ __typename?: 'AvailableTo'; availableToDate: any; availableCountDown: number }>;
	seenState: { __typename?: 'ShowSeenState'; progress: number };
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type PosterTitle_Show_Fragment = {
	__typename: 'Show';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	content: {
		__typename?: 'ShowContent';
		title: string;
		posterUrl?: string | null;
		fullPath: string;
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseDate?: any | null;
			releaseCountDown?: number | null;
		}>;
		scoring: {
			__typename?: 'Scoring';
			imdbScore?: number | null;
			imdbVotes?: number | null;
			tmdbPopularity?: number | null;
			tmdbScore?: number | null;
			jwRating?: number | null;
			tomatoMeter?: number | null;
			certifiedFresh?: boolean | null;
		};
	};
	watchNowOffer?: {
		__typename: 'Offer';
		id: string;
		standardWebURL?: string | null;
		preAffiliatedStandardWebURL?: string | null;
		streamUrl?: string | null;
		retailPrice?: string | null;
		retailPriceValue?: number | null;
		lastChangeRetailPriceValue?: number | null;
		currency?: string | null;
		presentationType: Types.PresentationType;
		monetizationType: Types.MonetizationType;
		availableTo?: any | null;
		dateCreated?: any | null;
		newElementCount?: number | null;
		package: {
			__typename?: 'Package';
			id: string;
			icon: string;
			packageId: number;
			clearName: string;
			shortName: string;
			technicalName: string;
			iconWide: string;
			hasRectangularIcon: boolean;
		};
	} | null;
	availableTo: Array<{ __typename?: 'AvailableTo'; availableToDate: any; availableCountDown: number }>;
	watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
	seenState: { __typename?: 'ShowSeenState'; progress: number };
	tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
};

export type PosterTitleFragment = PosterTitle_Movie_Fragment | PosterTitle_Season_Fragment | PosterTitle_Show_Fragment;

export const PosterTitleFragmentDoc = gql`
	fragment PosterTitle on MovieOrShowOrSeason {
		__typename
		id
		objectId
		objectType
		content(country: $country, language: $language) {
			title
			posterUrl
			fullPath
			upcomingReleases {
				releaseDate
				releaseCountDown(country: $country)
			}
			scoring {
				imdbScore
				imdbVotes
				tmdbPopularity
				tmdbScore
				jwRating
				tomatoMeter
				certifiedFresh
			}
		}
		watchNowOffer(country: $country, platform: $platform) {
			...WatchNowOffer
		}
		availableTo(country: $country, platform: $platform) {
			availableToDate
			availableCountDown(country: $country)
		}
		... on Season {
			content(country: $country, language: $language) {
				seasonNumber
			}
			show {
				__typename
				id
				objectId
				objectType
				content(country: $country, language: $language) {
					title
					fullPath
				}
				watchNowOffer(country: $country, platform: $platform) {
					...WatchNowOffer
				}
			}
		}
		...TitleListData
	}
	${WatchNowOfferFragmentDoc}
	${TitleListDataFragmentDoc}
`;
