import type { MaybeRefOrGetter } from '@vueuse/core';
import type { ShareMethod } from '@/enums/share-methods';
import type { ProfileInfoFragment } from '@/features/user-profile/graphql/fragments/ProfileInfo.fragment';
import type { TextRecommendationFragment } from '@/features/why-to-watch/graphql/fragments/TextRecommendation.fragment';
import type {
	LikeTextRecommendationMutation,
	LikeTextRecommendationMutationVariables,
} from '@/features/why-to-watch/graphql/mutations/LikeTextRecommendation.mutation';

import { TextRecommendationFragmentDoc } from '@/features/why-to-watch/graphql/fragments/TextRecommendation.fragment';
import { LikeTextRecommendationDocument } from '@/features/why-to-watch/graphql/mutations/LikeTextRecommendation.mutation';

import { ShareHelper } from '@/helpers/share-helper';
import { formatDateTime } from '@/helpers/date-helper';

import { useLanguageStore } from '@/helpers/composables/useStores';
import { useMutation } from '@/helpers/composables/useApollo';
import { useI18n } from '@/helpers/composables/useI18n';

import { toValue } from '@vueuse/core';
import { computed } from 'vue';

interface UseTextRecommendationOptions {
	textRecommendation: MaybeRefOrGetter<TextRecommendationFragment>;
}

export function useTextRecommendation({ textRecommendation }: UseTextRecommendationOptions) {
	const { t } = useI18n();
	const languageStore = useLanguageStore();

	const likedByUser = computed(() => toValue(textRecommendation).likedByUser ?? false);
	const likedCount = computed(() => toValue(textRecommendation).likeCount ?? 0);
	const ownedByUser = computed(() => toValue(textRecommendation).ownedByUser ?? 0);

	const textRecTitle = computed(() => toValue(textRecommendation).title);

	const profile = computed(() => toValue(textRecommendation).profile);

	const tagsString = computed(() => {
		const { tags } = toValue(textRecommendation);
		if (tags == null || tags.length === 0) return null;

		return tags.map(tag => `#${tag.translatedName}`).join(' ');
	});

	const date = computed(() =>
		formatDateTime(
			new Date(toValue(textRecommendation).watchedAt),
			languageStore.language.value,
			languageStore.country.value,
			{ day: '2-digit', month: '2-digit', year: 'numeric' }
		)
	);

	const watchedInCinema = computed(() => toValue(textRecommendation).watchedOn?.packageId === 0);

	const watchedOnText = computed(() => {
		if (watchedInCinema.value) return t('WEBAPP_WATCHED_CINEMA', { date: date.value });

		return t('WEBAPP_WATCHED_PROVIDER', {
			provider: toValue(textRecommendation).watchedOn?.clearName,
			date: date.value,
		});
	});

	const shareId = computed(() => getTextRecAnchor(toValue(textRecommendation).profile));

	function shareRecommendation(method: ShareMethod) {
		if (textRecTitle.value == null) return;

		ShareHelper.share({
			title: textRecTitle.value,
			tracking: { label: 'why_to_watch' },
			content: {
				url: `${JW_CONFIG.DOMAIN}${textRecTitle.value.content.fullPath}#${shareId.value}`,
				text: `"${toValue(textRecommendation)?.body}" – ${toValue(textRecommendation)?.profile.displayName}`,
				method,
			},
			useNative: false,
		});
	}

	const { mutate: likeRecommendationMutation } = useMutation<
		LikeTextRecommendationMutation,
		LikeTextRecommendationMutationVariables
	>(LikeTextRecommendationDocument, () => ({
		update: (cache, { data }) => {
			if (data?.likeTextRecommendationV2 == null) return;

			const { textRecommendation } = data.likeTextRecommendationV2;
			cache.writeFragment({
				id: textRecommendation.id,
				data: textRecommendation,
				fragmentName: 'TextRecommendation',
				fragment: TextRecommendationFragmentDoc,
				variables: { excludeTextRecommendationTitle: false },
			});
		},
	}));

	return {
		profile,

		textRecTitle,

		likedByUser,
		likedCount,
		ownedByUser,
		tagsString,
		watchedOnText,
		shareRecommendation,

		likeRecommendationMutation,
	};
}

export function getTextRecAnchor(profile: MaybeRefOrGetter<ProfileInfoFragment>) {
	return toValue(profile)
		.displayName.toLowerCase()
		.replace(/[^a-zA-Z ]/g, '') // remove all special characters (keep spaces)
		.replaceAll(' ', '-');
}
