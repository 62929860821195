import { generate, type ToVariants } from '@/components/experiments/Core/generateBasicExperimentComponent';

// ?preferred_variant=APPLE_BUYBOX_PROMOTIONS_EXP:control
// ?preferred_variant=APPLE_BUYBOX_PROMOTIONS_EXP:variant_1
// ?preferred_variant=APPLE_BUYBOX_PROMOTIONS_EXP:variant_2
// ?preferred_variant=APPLE_BUYBOX_PROMOTIONS_EXP:variant_3
// ?preferred_variant=APPLE_BUYBOX_PROMOTIONS_EXP:variant_4

// ?remove_preferred_experiment=APPLE_BUYBOX_PROMOTIONS_EXP

export const AppleBuyboxPromotionExpName = 'APPLE_BUYBOX_PROMOTIONS_EXP' as const;

export type AppleBuyboxPromotionExpVariants = ToVariants<typeof AppleBuyboxPromotionExpVariants>;

export const AppleBuyboxPromotionExpVariants = {
	CONTROL: 'control',
	VARIANT_1: 'variant_1',
	VARIANT_2: 'variant_2',
	VARIANT_3: 'variant_3',
	VARIANT_4: 'variant_4',
} as const;

export default generate(AppleBuyboxPromotionExpName, AppleBuyboxPromotionExpVariants);

export const appleBuyboxPromoExpTags = {
	[AppleBuyboxPromotionExpVariants.CONTROL]: {
		itsct: 'justwatch_tv_6',
		ctSuffix: '-pwc',
	},
	[AppleBuyboxPromotionExpVariants.VARIANT_1]: {
		itsct: 'justwatch_tv_2',
		ctSuffix: '-pw1',
	},
	[AppleBuyboxPromotionExpVariants.VARIANT_2]: {
		itsct: 'justwatch_tv_7',
		ctSuffix: '-pw2',
	},
	[AppleBuyboxPromotionExpVariants.VARIANT_3]: {
		itsct: 'justwatch_tv_9',
		ctSuffix: '-pw3',
	},
	[AppleBuyboxPromotionExpVariants.VARIANT_4]: {
		itsct: 'justwatch_tv_5',
		ctSuffix: '-pw4',
	},
};
