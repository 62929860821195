const illegalMetaDescriptionExpUrls = {
	group1: [
		'/us/movie/hello-love-again',
		'/us/movie/the-order-2024',
		'/us/movie/love-2015',
		'/us/tv-show/euphoria',
		'/us/movie/we-live-in-time',
		'/us/tv-show/rick-and-morty',
		'/us/movie/365-days',
		'/us/tv-show/twin-peaks',
		'/us/tv-show/shameless',
		'/us/movie/red-white-and-royal-blue',
		'/us/tv-show/bleach',
		'/us/movie/hes-just-not-that-into-you',
		'/us/movie/parthenope-2024',
		'/us/tv-show/greys-anatomy',
		'/us/tv-show/mr-robot',
		'/us/tv-show/when-the-phone-rings',
		'/us/tv-show/the-summer-i-turned-pretty',
		'/us/tv-show/the-amazing-world-of-gumball',
		'/us/tv-show/jujutsu-kaisen',
		'/us/movie/kingdom-of-heaven-directors-cut',
		'/us/movie/rape-zombie-lust-of-the-dead',
		'/us/movie/the-dictator',
		'/us/tv-show/the-simpsons',
		'/us/movie/sicario',
		'/us/movie/going-places',
		'/us/movie/500-days-of-summer',
		'/us/movie/the-holdovers',
		'/us/tv-show/young-sheldon',
		'/us/movie/speak-no-evil-2024',
		'/us/tv-show/everyday-life-with-monster-girls',
		'/us/tv-show/tulsa-king',
		'/us/tv-show/dexter-new-blood',
		'/us/tv-show/fresh-off-the-boat',
		'/us/movie/no-country-for-old-men',
		'/us/movie/guardians-of-the-galaxy-vol-3',
		'/us/tv-show/haisukurudxd',
		'/us/movie/crazy-rich-asians',
		'/us/tv-show/light-shop',
		'/us/movie/the-sower',
		'/us/movie/x-rated-the-greatest-adult-movies-of-all-time',
		'/us/movie/mask',
		'/us/tv-show/a-discovery-of-witches',
		'/us/movie/my-mother',
		'/us/tv-show/the-nanny',
		'/us/movie/porkys',
		'/us/movie/airplane',
		'/us/movie/the-beekeeper-2024',
		'/us/tv-show/pen15',
		'/us/movie/tenet',
		'/us/movie/now-you-see-me',
		'/us/movie/renaissance-a-film-by-beyonce',
		'/us/tv-show/fanta-g-spot',
		'/us/movie/kids',
		'/us/movie/the-shining',
		'/us/tv-show/90-day-fiance',
		'/us/movie/tokyo-godfathers',
		'/us/movie/ghost-graduation',
		'/us/movie/the-great-gatsby-3d',
		'/us/movie/red-nose-day-actually',
		'/us/tv-show/the-chi',
		'/us/movie/the-pursuit-of-happyness',
		'/us/movie/akira',
		'/us/movie/girls-will-be-girls-2024',
		'/us/tv-show/disclaimer',
		'/us/movie/monty-python-and-the-holy-grail',
		'/us/tv-show/desperate-housewives',
		'/us/movie/the-secret-lives-of-cheerleaders',
		'/us/movie/the-northman',
		'/us/movie/sex-positive-2024',
		'/us/tv-show/the-wire',
		'/us/movie/harry-potter-and-the-prisoner-of-azkaban',
		'/us/movie/pretty-woman',
		'/us/movie/the-bikeriders',
		'/us/tv-show/love-your-enemy',
		'/us/tv-show/the-flash',
		'/us/tv-show/tacoma-fd',
		'/us/tv-show/monsters-the-lyle-and-erik-menendez-story',
		'/us/movie/v-for-vendetta',
		'/us/movie/kingdom-of-the-planet-of-the-apes',
		'/us/tv-show/legends-of-chamberlain-heights',
		'/us/movie/the-crow-2024',
		'/us/movie/greyhound',
		'/us/movie/airheads',
		'/us/movie/never-let-go-2024',
		'/us/movie/diary-of-a-nymphomaniac',
		'/us/movie/american-beach-house',
		'/us/movie/jex',
		'/us/tv-show/the-king-of-the-desert',
		'/us/movie/men-of-divorce',
		'/us/tv-show/submission',
		'/us/tv-show/how-to-get-away-with-murder',
		'/us/tv-show/power-book-2-ghost',
		'/us/tv-show/love-in-the-air',
		'/us/tv-show/what-we-do-in-the-shadows',
		// UK
		'/uk/movie/babygirl-2024',
		'/uk/movie/gladiator',
		'/uk/movie/the-lord-of-the-rings-the-fellowship-of-the-ring',
		'/uk/movie/interstellar',
		'/uk/movie/it-ends-with-us',
		'/uk/tv-series/the-sopranos',
		'/uk/movie/alien-romulus',
		'/uk/movie/the-room-next-door',
		'/uk/movie/dil-diyan-gallan',
		'/uk/tv-series/euphoria',
		'/uk/tv-series/high-potential',
		'/uk/movie/fifty-shades-darker',
		'/uk/tv-series/supernatural',
		'/uk/movie/wonka',
		'/uk/tv-series/black-doves',
		'/uk/movie/xx',
		'/uk/tv-series/earth-abides/season-1',
		'/uk/movie/the-second-wife',
		'/uk/tv-series/outlander',
		'/uk/movie/the-batman',
		'/uk/movie/die-hard',
		'/uk/movie/rise-of-the-guardians',
		'/uk/movie/spider-man-into-the-spider-verse-2',
		'/uk/movie/back-to-the-future',
		'/uk/movie/serendipity',
		'/uk/movie/parthenope',
		'/uk/tv-series/abbott-elementary/season-4',
		'/uk/movie/taking-chance',
		'/uk/movie/i-saw-the-tv-glow',
		// AU
		'/au/movie/sidelined',
		'/au/movie/young-hearts-2024',
		'/au/movie/buried-in-barstow',
		'/au/movie/queen-of-hearts',
		'/au/tv-show/the-rookie/season-6',
		'/au/movie/pushpa-the-rule-part-2',
		'/au/movie/unbreakable-the-jelena-dokic-story',
		'/au/movie/conclave',
		'/au/movie/heretic-2024',
		'/au/movie/flow-2024',
		'/au/movie/total-dhamaal',
		'/au/movie/australia',
		'/au/movie/a-wifes-nightmare',
		'/au/movie/all-we-imagine-as-light',
		'/au/movie/the-order',
		'/au/movie/venom-3-2024',
		'/au/movie/christmas-in-the-spotlight',
		'/au/movie/lee-2024',
		'/au/movie/home-alone-2',
		'/au/movie/the-program-2024',
		'/au/movie/the-wild-robot',
	],
	group2: [
		'/us/movie/how-the-grinch-stole-christmas',
		'/us/movie/in-your-eyes',
		'/us/movie/spider-man-no-way-home',
		'/us/tv-show/dexter-original-sin',
		'/us/movie/the-legend-of-maula-jatt',
		'/us/movie/kiss-and-kill',
		'/us/tv-show/modern-family',
		'/us/tv-show/from',
		'/us/movie/alien-romulus',
		'/us/movie/young-frankenstein',
		'/us/tv-show/friends',
		'/us/movie/dogma',
		'/us/movie/9-songs',
		'/us/tv-show/hero-inside',
		'/us/tv-show/bleach-thousand-year-blood-war',
		'/us/movie/oppenheimer',
		'/us/tv-show/rupauls-drag-race',
		'/us/tv-show/the-eminence-in-shadow',
		'/us/movie/a-silent-voice',
		'/us/movie/maria-2024',
		'/us/movie/it-boy',
		'/us/movie/oceans-eleven',
		'/us/movie/a-tale-of-legendary-libido',
		'/us/movie/the-big-short',
		'/us/movie/forrest-gump',
		'/us/tv-show/avatar-the-last-airbender',
		'/us/movie/malena',
		'/us/movie/eyes-wide-shut',
		'/us/tv-show/suits',
		'/us/tv-show/death-note',
		'/us/movie/lord-of-the-rings-i-the-fellowship-of-the-ring',
		'/us/movie/hidden-face-2025',
		'/us/tv-show/that-70s-show',
		'/us/movie/pirates-of-the-caribbean-the-curse-of-the-black-pearl',
		'/us/tv-show/the-walking-dead-the-ones-who-live',
		'/us/tv-show/why-women-kill',
		'/us/movie/everything-everywhere-all-at-once',
		'/us/movie/perfect-days',
		'/us/movie/hacksaw-ridge',
		'/us/movie/in-the-realm-of-the-senses',
		'/us/tv-show/invincible',
		'/us/movie/six-swedish-girls-in-a-boarding-school',
		'/us/tv-show/the-real-housewives-of-beverly-hills',
		'/us/tv-show/the-pradeeps-of-pittsburgh',
		'/us/movie/freres',
		'/us/tv-show/dragon-ball-super',
		'/us/movie/charm-city-kings',
		'/us/movie/ghosted-2023',
		'/us/movie/sully',
		'/us/movie/the-menu-2022',
		'/us/movie/transformers-the-last-knight',
		'/us/movie/terminator-3-rise-of-the-machines',
		'/us/movie/how-much-do-you-love-me',
		'/us/movie/about-time',
		'/us/movie/world-war-z-3d',
		'/us/tv-show/the-tudors',
		'/us/movie/my-old-ass',
		'/us/tv-show/wentworth',
		'/us/movie/the-day-of-the-jackal',
		'/us/movie/wrath-of-man',
		'/us/tv-show/running-man',
		'/us/tv-show/gossip-girl',
		'/us/tv-show/f4-thailand-boys-over-flowers',
		'/us/movie/arrival-2016',
		'/us/movie/klaus',
		'/us/movie/sam-and-colby-the-legend-of-skinwalker-ranch',
		'/us/movie/the-hunt-for-red-october',
		'/us/movie/the-lion-king-2019',
		'/us/movie/room-in-rome',
		'/us/movie/the-big-lebowski',
		'/us/movie/wolves-2024',
		'/us/tv-show/star-vs-the-forces-of-evil',
		'/us/movie/meet-joe-black',
		'/us/tv-show/reign',
		'/us/movie/death-becomes-her',
		'/us/movie/the-dreamers-2003',
		'/us/tv-show/the-temperature-of-language-our-nineteen',
		'/us/movie/city-of-god',
		'/us/movie/madly',
		'/us/movie/pacific-rim',
		'/us/movie/the-great-warning',
		'/us/movie/wooden-crosses',
		'/us/movie/hommes-en-noir',
		'/us/movie/thelma-2024',
		'/us/movie/the-new-year-that-never-came',
		'/us/tv-show/regular-show',
		'/us/movie/the-magnificent-seven-2016',
		'/us/tv-show/hardcore-pawn',
		'/us/movie/my-house-in-umbria',
		'/us/tv-show/a-different-world',
		'/us/tv-show/good-girls',
		'/us/tv-show/oshi-no-ko',
		'/us/tv-show/love-island-2019',
		'/us/tv-show/rented-in-finland',
		// UK
		'/uk/movie/anora',
		'/uk/movie/moana-2',
		'/uk/movie/elf',
		'/uk/movie/sex-party-and-lies',
		'/uk/movie/fifty-shades-of-grey',
		'/uk/movie/terrifier-3',
		'/uk/movie/hello-love-again',
		'/uk/movie/me-before-you',
		'/uk/movie/below-her-mouth',
		'/uk/tv-series/game-of-thrones',
		'/uk/movie/civil-war-2024-0',
		'/uk/movie/the-notebook',
		'/uk/movie/the-holdovers',
		'/uk/movie/cold-feet-2018',
		'/uk/movie/national-lampoons-christmas-vacation',
		'/uk/movie/the-lion-king-2019',
		'/uk/movie/redeeming-love',
		'/uk/movie/the-gentlemen',
		'/uk/movie/twisters',
		'/uk/movie/john-wick',
		'/uk/tv-series/south-park',
		'/uk/movie/knives-out',
		'/uk/movie/legally-blonde',
		'/uk/movie/love-in-the-big-city',
		'/uk/tv-series/mrs-fletcher',
		'/uk/movie/despicable-me-4',
		'/uk/movie/scary-movie',
		'/uk/movie/trap-2024',
		'/uk/movie/six-triple-eight',
		// AU
		'/au/movie/tamil-rockers',
		'/au/movie/sonic-the-hedgehog-3',
		'/au/movie/mufasa-the-lion-king',
		'/au/movie/yparho',
		'/au/movie/cruel-intentions',
		'/au/movie/sidelined-the-qb-and-me',
		'/au/movie/red-one',
		'/au/movie/sonic-the-hedgehog-2',
		'/au/movie/better-man',
		'/au/movie/queer',
		'/au/movie/a-real-pain',
		'/au/movie/distant',
		'/au/movie/dear-zachary-a-letter-to-a-son-about-his-father',
		'/au/tv-show/one-punch-man',
		'/au/movie/the-wrong-stepmother',
		'/au/movie/the-villagers',
		'/au/movie/shame',
		'/au/movie/despatch',
		'/au/movie/felicity',
		'/au/movie/the-sower-2017',
		'/au/movie/love-2015',
	],
	group3: [
		'/us/tv-show/the-rookie',
		'/us/tv-show/family-guy',
		'/us/movie/the-seed-of-the-sacred-fig',
		'/us/movie/longlegs',
		'/us/movie/salo-or-the-120-days-of-sodom',
		'/us/tv-show/the-titans-bride',
		'/us/movie/den-of-thieves',
		'/us/movie/sarangi-a-journey-of-purna-bahadur',
		'/us/movie/sex-party-and-lies',
		'/us/tv-show/the-walking-dead',
		'/us/movie/illegal',
		'/us/tv-show/how-i-met-your-mother',
		'/us/tv-show/game-of-thrones',
		'/us/movie/django-unchained',
		'/us/tv-show/vermeil-in-gold',
		'/us/movie/cruel-intentions-1',
		'/us/tv-show/dragon-ball-z',
		'/us/tv-show/snowfall',
		'/us/movie/the-cowboy-way',
		'/us/movie/nymphomaniac-volume-i',
		'/us/tv-show/superman-and-lois',
		'/us/tv-show/cobra-kai',
		'/us/tv-show/normal-people',
		'/us/tv-show/one-punch-man',
		'/us/tv-show/naruto',
		'/us/movie/piece-by-piece-2024',
		'/us/movie/godzilla-x-kong-the-new-empire',
		'/us/movie/the-nice-guys',
		'/us/tv-show/the-penguin',
		'/us/movie/debbie-does-dallas',
		'/us/tv-show/the-bureau-2015',
		'/us/tv-show/prison-break',
		'/us/movie/hindi-medium',
		'/us/tv-show/the-boys',
		'/us/movie/jurassic-world-dominion',
		'/us/tv-show/westworld',
		'/us/movie/blade-runner',
		'/us/movie/scary-movie',
		'/us/movie/lolita-1997',
		'/us/movie/carry-on-2024',
		'/us/tv-show/zindagi-in-short',
		'/us/movie/the-equalizer',
		'/us/tv-show/miraculous-tales-of-ladybug-and-cat-noir',
		'/us/tv-show/the-seven-deadly-sins-four-knights-of-the-apocalypse',
		'/us/tv-show/tell-me-lies',
		'/us/tv-show/star-wars-skeleton-crew',
		'/us/tv-show/true-beauty-2020',
		'/us/movie/sisu-2023',
		'/us/movie/civil-war-2024',
		'/us/movie/the-handmaiden',
		'/us/movie/erotic-ghost-story',
		'/us/tv-show/spartacus',
		'/us/movie/into-the-wild',
		'/us/movie/no-time-to-die-2020',
		'/us/movie/gone-girl',
		'/us/movie/sleeping-with-my-student',
		'/us/movie/32',
		'/us/tv-show/moon-knight',
		'/us/tv-show/chernobyl',
		'/us/tv-show/shimoneta-a-boring-world-where-the-concept-of-dirty-jokes-doesnt-exist',
		'/us/tv-show/dream-productions',
		'/us/tv-show/knuckles',
		'/us/movie/the-sabarmati-report',
		'/us/movie/the-servant-2010',
		'/us/tv-show/seven-mortal-sins',
		'/us/movie/mixed-nuts',
		'/us/movie/killem-all',
		'/us/tv-show/interview-with-the-vampire',
		'/us/movie/edge-of-tomorrow',
		'/us/movie/the-lord-of-the-rings-the-fellowship-of-the-ring-extended-edition-2002',
		'/us/movie/harry-potter-and-the-goblet-of-fire',
		'/us/tv-show/the-lord-of-the-rings-the-rings-of-power',
		'/us/movie/the-wingwalker',
		'/us/movie/the-mask',
		'/us/movie/family-practice',
		'/us/movie/harry-potter-7-deathly-hallows-part-1',
		'/us/tv-show/lost',
		'/us/movie/harry-potter-and-the-half-blood-prince',
		'/us/movie/indiana-jones-and-the-raiders-of-the-lost-ark',
		'/us/tv-show/malcolm-in-the-middle',
		'/us/movie/dime-lo-que-quieres-de-verdad',
		'/us/tv-show/the-good-wife',
		'/us/movie/the-jackal',
		'/us/movie/bridget-jones-diary',
		'/us/movie/the-grand-budapest-hotel',
		'/us/movie/pellichoopulu',
		'/us/tv-show/house-of-the-dragon',
		'/us/movie/us-x-her',
		'/us/movie/e-t-the-extra-terrestrial',
		'/us/movie/the-matrix-2-reloaded',
		'/us/tv-show/you',
		'/us/tv-show/project-7',
		'/us/tv-show/gavin-and-stacey',
		'/us/tv-show/game-world-reincarnation',
		// UK
		'/uk/movie/paddington-in-peru',
		'/uk/movie/the-salt-path',
		'/uk/tv-series/dating-naked',
		'/uk/tv-series/law-and-order-special-victims-unit',
		'/uk/movie/dune-2020',
		'/uk/tv-series/creature-commandos',
		'/uk/movie/my-hero-academia-the-movie-youre-next',
		'/uk/movie/dune-part-two',
		'/uk/movie/the-truman-show',
		'/uk/tv-series/parks-and-recreation',
		'/uk/movie/desire-2011',
		'/uk/movie/moana',
		'/uk/movie/arthur-christmas',
		'/uk/tv-series/georgie-and-mandys-first-marriage/season-1',
		'/uk/movie/spider-man-no-way-home',
		'/uk/tv-series/house',
		'/uk/tv-series/naruto',
		'/uk/tv-series/dexter-new-blood',
		'/uk/movie/longlegs',
		'/uk/movie/pleasure-or-pain',
		'/uk/movie/the-sabarmati-report',
		'/uk/movie/se7en',
		'/uk/movie/beetlejuice-2-2025',
		'/uk/tv-series/brassic/season-5',
		'/uk/movie/kat',
		'/uk/tv-series/evil',
		'/uk/tv-series/silo',
		'/uk/tv-series/eastenders',
		'/uk/movie/brendan-gleesons-farewell-to-hughess',
		// AU
		'/au/movie/wicked-2021',
		'/au/movie/the-skin-i-live-in',
		'/au/movie/student-services',
		'/au/movie/sex-party-and-lies',
		'/au/movie/desire',
		'/au/movie/50-shades-of-grey',
		'/au/movie/hello-love-again',
		'/au/movie/28-hotel-rooms',
		'/au/movie/home-alone-1',
		'/au/movie/we-live-in-time',
		'/au/movie/downloaded',
		'/au/movie/the-lord-of-the-rings-the-war-of-the-rohirrim',
		'/au/movie/house-of-pleasures',
		'/au/tv-show/what-if-2021/season-3',
		'/au/movie/crocodile-dundee',
		'/au/movie/the-lion-king-2019',
		'/au/movie/quills',
		'/au/movie/ghost-graduation',
		'/au/movie/y2k-2024',
		'/au/movie/the-apprentice',
		'/au/movie/the-great-warning',
	],
	group4: [
		'/us/tv-show/one-piece',
		'/us/movie/memoir-of-a-snail',
		'/us/movie/hard-truths',
		'/us/movie/how-to-train-your-dragon',
		'/us/movie/elevation-2024',
		'/us/movie/john-wick',
		'/us/tv-show/the-vampire-diaries',
		'/us/tv-show/2-broke-girls',
		'/us/tv-show/attack-on-titan',
		'/us/tv-show/redo-of-healer',
		'/us/movie/green-book',
		'/us/tv-show/silo',
		'/us/tv-show/demon-slayer-kimetsu-no-yaiba',
		'/us/movie/10-things-i-hate-about-you',
		'/us/movie/transformers-rise-of-the-beasts-2023',
		'/us/movie/200-cigarettes',
		'/us/movie/strange-darling',
		'/us/tv-show/family-by-choice',
		'/us/movie/the-return-2024-0',
		'/us/tv-show/the-big-bang-theory',
		'/us/movie/john-wick-chapter-4',
		'/us/movie/avatar-2',
		'/us/movie/the-nightmare-before-christmas',
		'/us/tv-show/supernatural',
		'/us/movie/harry-potter-and-the-order-of-the-phoenix',
		'/us/tv-show/jane-the-virgin',
		'/us/tv-show/breaking-bad',
		'/us/movie/the-hobbit-an-unexpected-journey',
		'/us/movie/the-fate-of-the-furious',
		'/us/movie/spider-man-into-the-spider-verse-2',
		'/us/movie/the-hunger-games-the-ballad-of-songbirds-and-snakes',
		'/us/movie/dead-poets-society',
		'/us/movie/slumdog-millionaire',
		'/us/tv-show/the-100',
		'/us/movie/when-harry-met-sally',
		'/us/tv-show/hpi',
		'/us/movie/down-2019',
		'/us/movie/les-chambres-rouges',
		'/us/tv-show/teen-titans',
		'/us/movie/flipped',
		'/us/movie/dune-part-two-2023',
		'/us/movie/five-nights-at-freddys',
		'/us/tv-show/the-last-of-us',
		'/us/movie/guardians-of-the-galaxy',
		'/us/tv-show/the-big-c',
		'/us/movie/lady-ninja-reflections-of-darkness',
		'/us/movie/green-street',
		'/us/movie/paddington',
		'/us/tv-show/stranger-things',
		'/us/tv-show/henry-danger',
		'/us/tv-show/sex-and-the-city',
		'/us/tv-show/gotham',
		'/us/movie/inception',
		'/us/movie/thirteen',
		'/us/movie/escape-from-pleasure-planet',
		'/us/movie/the-neverending-story',
		'/us/movie/little-man-tate',
		'/us/movie/look-whos-back',
		'/us/movie/call-boy',
		'/us/movie/girl-in-the-basement',
		'/us/movie/rocky',
		'/us/tv-show/initial-d-first-stage',
		'/us/tv-show/vikings',
		'/us/movie/nacho-libre',
		'/us/tv-show/academy-apocalypse-highschool-of-the-dead',
		'/us/movie/i-am-legend',
		'/us/movie/parasite-2019',
		'/us/movie/the-intouchables',
		'/us/tv-show/the-edge-of-sleep',
		'/us/movie/the-poker-house',
		'/us/movie/the-town',
		'/us/movie/revenge-of-the-nerds',
		'/us/movie/john-wick-chapter-two',
		'/us/movie/anyone-but-you',
		'/us/movie/what-we-do-in-the-shadows',
		'/us/tv-show/smallville',
		'/us/tv-show/sparkling-watermelon',
		'/us/tv-show/say-nothing',
		'/us/movie/late-night-with-the-devil',
		'/us/movie/3-d-sex-and-zen-extreme-ecstasy',
		'/us/tv-show/lingerie',
		'/us/tv-show/wwe-nxt',
		'/us/movie/dangal',
		'/us/tv-show/super-dragon-ball-heroes',
		'/us/tv-show/adams-sweet-agony',
		'/us/tv-show/fellow-travelers',
		'/us/movie/casino',
		'/us/movie/123',
		'/us/movie/maze-runner-the-death-cure',
		'/us/movie/a-beautiful-mind',
		'/us/tv-show/love-in-the-big-city',
		'/us/tv-show/the-seven-deadly-sins',
		'/us/tv-show/ben-10',
		'/us/tv-show/co-ed-confidential',
		// UK
		'/uk/tv-series/the-day-of-the-jackal',
		'/uk/movie/virgin-territory',
		'/uk/tv-series/the-rookie',
		'/uk/movie/the-wizard-of-oz',
		'/uk/movie/kraven-the-hunter',
		'/uk/movie/girl-in-the-basement',
		'/uk/tv-series/from/season-3',
		'/uk/movie/illegal',
		'/uk/movie/nymphomaniac-vol-i',
		'/uk/movie/365-dni',
		'/uk/tv-series/emmerdale',
		'/uk/movie/good-will-hunting',
		'/uk/tv-series/teen-wolf',
		'/uk/tv-series/come-fly-with-me',
		'/uk/movie/little-man-tate',
		'/uk/tv-series/say-nothing',
		'/uk/movie/the-outrun',
		'/uk/movie/oppenheimer',
		'/uk/movie/the-godfather',
		'/uk/tv-series/the-take',
		'/uk/movie/casatoria',
		'/uk/movie/herbie-fully-loaded',
		'/uk/movie/coraline',
		'/uk/movie/the-fallout',
		'/uk/movie/the-girl-who-escaped-the-kara-robinson-story',
		'/uk/tv-series/what-if/season-3',
		'/uk/movie/vacation-2015',
		'/uk/tv-series/solo-leveling',
		'/uk/movie/perfect-days',
		// AU
		'/au/movie/zink',
		'/au/movie/moana-2',
		'/au/movie/homestead-2024',
		'/au/movie/ore-dake-level-up-na-ken-reawakening',
		'/au/movie/viduthalai-part-2',
		'/au/movie/the-substance',
		'/au/movie/gladiator-2',
		'/au/movie/life-size',
		'/au/movie/it-ends-with-us',
		'/au/movie/sookshma-darshini',
		'/au/movie/anora',
		'/au/movie/free',
		'/au/movie/fidelity',
		'/au/movie/madly',
		'/au/movie/love-like-poison',
		'/au/tv-show/creature-commandos',
		'/au/movie/in-secret',
		'/au/movie/dog-pound',
		'/au/movie/basic-instinct',
	],
	group5: [
		'/us/movie/sling-blade',
		'/us/movie/downloaded',
		'/us/movie/the-basketball-diaries',
		'/us/movie/the-apprentice-2024-0',
		'/us/tv-show/yosuganosora',
		'/us/tv-show/law-and-order-special-victims-unit',
		'/us/movie/blue-is-the-warmest-colour',
		'/us/tv-show/dexter',
		'/us/movie/six-triple-eight',
		'/us/tv-show/outer-banks',
		'/us/movie/run-hide-fight',
		'/us/movie/emilia-perez',
		'/us/movie/the-witch-2016',
		'/us/movie/the-matchmakers-playbook',
		'/us/movie/american-pie-1',
		'/us/movie/the-maze-runner',
		'/us/tv-show/teen-wolf',
		'/us/tv-show/doctor-who',
		'/us/movie/wrong-turn-6-last-resort',
		'/us/movie/here-2024-0',
		'/us/movie/going-the-distance-2004',
		'/us/tv-show/the-boondocks',
		'/us/tv-show/why-the-hell-are-you-here-teacher',
		'/us/tv-show/the-perfect-prince-loves-me-the-side-character',
		'/us/tv-show/evil',
		'/us/movie/how-to-lose-a-guy-in-10-days',
		'/us/tv-show/dragon-ball',
		'/us/movie/how-to-train-your-dragon-the-hidden-world',
		'/us/movie/casino-royale-uncut-edition',
		'/us/tv-show/hazbin-hotel',
		'/us/tv-show/arcane',
		'/us/movie/the-shawshank-redemption',
		'/us/tv-show/naruto-shippuden',
		'/us/tv-show/all-american',
		'/us/movie/kabhi-eid-kabhi-diwali',
		'/us/movie/catch-me-if-you-can',
		'/us/movie/the-gangster-the-cop-the-devil',
		'/us/tv-show/hannibal',
		'/us/movie/oh-canada',
		'/us/tv-show/my-hero-academia',
		'/us/movie/river-of-blood',
		'/us/movie/moneyball',
		'/us/tv-show/strangers-from-hell',
		'/us/movie/austin-powers-international-man-of-mystery',
		'/us/tv-show/adventure-time',
		'/us/movie/kama-sutra-a-tale-of-love',
		'/us/movie/free',
		'/us/movie/prison-break-the-final-break',
		'/us/movie/trap-2024',
		'/us/tv-show/earth-abides',
		'/us/movie/reagan-2023',
		'/us/tv-show/victorious',
		'/us/movie/bad-sister',
		'/us/tv-show/danziyonnichu-hui-iwoqiu-merunohajian-wei-tsuteirudarouka',
		'/us/movie/the-autopsy-of-jane-doe',
		'/us/movie/mission-impossible-7',
		'/us/movie/les-miserables',
		'/us/movie/the-last-american-virgin',
		'/us/movie/joker-2',
		'/us/movie/harry-potter-and-the-chamber-of-secrets',
		'/us/movie/better-off-dead',
		'/us/movie/trading-places',
		'/us/movie/a-minecraft-movie',
		'/us/tv-show/full-house',
		'/us/movie/i-am-sam',
		'/us/movie/the-outrun',
		'/us/tv-show/law-and-order',
		'/us/movie/amadeus',
		'/us/tv-show/survivor',
		'/us/tv-show/the-good-doctor',
		'/us/movie/in-bruges',
		'/us/tv-show/mrs-fletcher',
		'/us/movie/fast-and-furious-9',
		'/us/movie/dungeons-and-dragons-2023',
		'/us/tv-show/the-ms-pat-show',
		'/us/movie/white-bird',
		'/us/tv-show/top-gear',
		'/us/movie/katies-mom',
		'/us/movie/dragon-ball-super-super-hero',
		'/us/movie/killing-me-softly',
		'/us/movie/kill-bill-vol-1',
		'/us/tv-show/lessons-in-chemistry',
		'/us/movie/lost-in-translation',
		'/us/tv-show/wounded-love',
		'/us/movie/culpa-mia',
		'/us/movie/howls-moving-castle',
		'/us/tv-show/the-tale-of-lady-ok',
		'/us/movie/life-of-brian',
		'/us/movie/doctor-strange-in-the-multiverse-of-madness',
		'/us/tv-show/everybody-hates-chris',
		'/us/tv-show/gushing-over-magical-girls',
		'/us/tv-show/cracked',
		'/us/tv-show/adult-material',
		'/us/tv-show/succession',
		// UK
		'/uk/movie/the-substance',
		'/uk/movie/we-live-in-time',
		'/uk/movie/123',
		'/uk/tv-series/ted-lasso',
		'/uk/movie/love-actually',
		'/uk/movie/deadpool-and-wolverine',
		'/uk/movie/the-holiday',
		'/uk/movie/the-polar-express',
		'/uk/tv-series/chernobyl',
		'/uk/movie/the-day-of-the-jackal',
		'/uk/tv-series/dexter-original-sin',
		'/uk/tv-series/superman-and-lois/season-4',
		'/uk/movie/cashback',
		'/uk/tv-series/tacoma-fd',
		'/uk/movie/bad-santa',
		'/uk/movie/shrek',
		'/uk/tv-series/sex-and-the-city',
		'/uk/movie/the-dark-knight',
		'/uk/tv-series/the-penguin',
		'/uk/movie/anyone-but-you',
		'/uk/movie/kneecap',
		'/uk/movie/obsessed-2014',
		'/uk/movie/shes-the-man',
		'/uk/tv-series/phoenix-nights',
		'/uk/tv-series/bad-sisters',
		'/uk/movie/christmas-in-the-spotlight',
		'/uk/tv-series/misfits',
		'/uk/movie/blink-twice',
		'/uk/movie/barbie-2023',
		// AU
		'/au/movie/babygirl-2024',
		'/au/movie/kraven-the-hunter',
		'/au/movie/melissa-p',
		'/au/movie/hidden-face',
		'/au/movie/angels-and-virgins',
		'/au/movie/my-hero-academia-the-movie-youre-next',
		'/au/movie/virgin-people',
		'/au/movie/the-parent-trap',
		'/au/movie/mere-brother-ki-dulhan',
		'/au/movie/the-second-wife',
		'/au/movie/milk-2017',
		'/au/movie/the-brutalist',
		'/au/movie/your-fault',
		'/au/movie/the-little-hours',
		'/au/movie/one-wild-moment',
		'/au/movie/pideme-lo-que-quieras',
		'/au/movie/nagordola',
		'/au/tv-show/gavin-and-stacey',
		'/au/movie/come-and-see',
		'/au/movie/life-feels-good',
	],
};

const illegalMetaRules = {
	group1: {
		lessThan22:
			'Find out how and where to watch {title} online on streaming services such as Netflix · Prime Video · Disney+ - inc. Free & 4K options!',
		lessThan33:
			'Find out how and where to watch {title} online on streaming services such as Netflix · Prime Video - inc. Free & 4K options!',
		lessThan53:
			'Find out where to watch {title} on streaming services such as Netflix · Prime Video inc. Free & 4K options!',
		default: 'Watch {title} on streaming services such as Netflix · Prime Video inc. Free & 4K options!',
	},
	group2: {
		lessThan22:
			'Watch {title} · Full movie online · On streaming services such as Netflix, Prime Video & Disney+ - inc. ✓ Free & ✓ 4K options!',
		lessThan33:
			'Watch {title} · Full movie online · On streaming services such as Netflix, Prime Video & Disney+ - inc. ✓ Free & ✓ 4K options!',
		lessThan53:
			'Watch {title} · Full movie · On streaming services such as Netflix, Prime Video inc. ✓ Free & ✓ 4K options!',
		default: 'Watch {title} · Full movie · On streaming services such as Netflix ✓ Free & ✓ 4K options!',
	},
	group3: {
		lessThan22:
			'Watch {title} · Full movie online for free · On streaming services such as Netflix, Prime Video & Disney+ - inc. 4K options!',
		lessThan33:
			'Watch {title} · Full movie online for free · On streaming services such as Netflix, Prime Video & Disney+ - inc. 4K options!',
		lessThan53:
			'Watch {title} · Full movie for free · On streaming services such as Netflix, Prime Video - inc. 4K options!',
		default: 'Watch {title} · Full movie for free · On streaming services such as Netflix inc. 4K options!',
	},
	group4: {
		lessThan22:
			'Watch {title} online ✓ Full movie ✓ Free & 4K options! On streaming services such as Netflix, Prime Video & Disney+.',
		lessThan33:
			'Watch {title} online ✓ Full movie ✓ Free & 4K options! On streaming services such as Netflix, Prime Video & Disney+.',
		lessThan53:
			'Watch {title} online ✓ Full movie ✓ Free & 4K options! On streaming services such as Netflix, Prime Video.',
		default: 'Watch {title} online ✓ Full movie ✓ Free & 4K options! On streaming services such as Netflix.',
	},
	group5: {
		lessThan22:
			'Watch {title} · Full movie online · Free & 4K options · On streaming services such as Netflix, Prime Video & Disney+.',
		lessThan33:
			'Watch {title} · Full movie online · Free & 4K options · On streaming services such as Netflix, Prime Video & Disney+.',
		lessThan53:
			'Watch {title} · Full movie online · Free & 4K options · On streaming services such as Netflix & Prime Video.',
		default: 'Watch {title} · Full movie · Free & 4K options · On streaming services such as Netflix.',
	},
};

type MetaGroups = keyof typeof illegalMetaDescriptionExpUrls;

export const ctrExperimentUrlsMap = new Map<string, MetaGroups>(
	Object.entries(illegalMetaDescriptionExpUrls).flatMap(([group, urls]) =>
		urls.map(url => [url, group as MetaGroups])
	)
);

const countries = ['AU', 'GB', 'US'] as const;

export function getCTRExperimentMetaDescription(title: string, group: MetaGroups) {
	return illegalMetaRules[group][getTitleLength(title)].replace('{title}', title);
}

function getTitleLength(title: string) {
	if (title.length < 22) return 'lessThan22';
	if (title.length < 33) return 'lessThan33';
	if (title.length < 53) return 'lessThan53';
	return 'default';
}

export function isCTRExperimentCountry(country: string): country is 'AU' | 'GB' | 'US' {
	return countries.includes(country as any);
}
